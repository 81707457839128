import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import styles from '../styles/Home.module.css';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

function Tabs(props: TabsProps) {
  const activeIndex = props.selectedIndex ?? 0;
  if (!props.children) {
    return <></>;
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        onClick={() => {
          if (props.onTabSelected) props.onTabSelected(index);
        }}
        aria-pressed={activeIndex === index}
      >
        {child?.props.label}
      </button>
    );
  });
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {props.children[activeIndex]}
    </div>
  );
}

function DemoMeetingTab({ label }: { label: string }) {
  const router = useRouter();
  const startMeeting = () => {
    router.push(`/rooms/${generateRoomId()}`);
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}>Start a meeting by pressing the button</p>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start a Meeting
      </button>
    </div>
  );
}

function CustomConnectionTab({ label }: { label: string }) {
  const router = useRouter();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const roomId = formData.get('roomId');
    router.push(`/rooms/${roomId}`);
  };
  return (
    <form className={styles.tabContent} onSubmit={onSubmit}>
      <p style={{ marginTop: 0 }}>
        Join existing room by typing the Room ID
      </p>
      <input
        className={styles.inputText}
        id="roomId"
        name="roomId"
        type="text"
        placeholder="Room ID"
        required
      />
      {/*<textarea*/}
      {/*  id="token"*/}
      {/*  name="token"*/}
      {/*  placeholder="Token"*/}
      {/*  required*/}
      {/*  rows={9}*/}
      {/*  style={{ padding: '1px 2px', fontSize: 'inherit', lineHeight: 'inherit' }}*/}
      {/*/>*/}
      <hr
        style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}
      />
      <button
        style={{ paddingInline: '1.25rem', width: '100%' }}
        className="lk-button"
        type="submit"
      >
        Join a Meeting
      </button>
    </form>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'join' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'join' : 'new';
    // const tab = 'demo';
    router.push({ query: { tab } });
  }
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          {/*<img src="/images/livekit-meet-home.svg" alt="LiveKit Meet" width="360" height="45" />*/}
          {/*<h2>*/}
          {/*  Open source video conferencing app built on{' '}*/}
          {/*  <a href="https://github.com/livekit/components-js?ref=meet" rel="noopener">*/}
          {/*    LiveKit&nbsp;Components*/}
          {/*  </a>*/}
          {/*  ,{' '}*/}
          {/*  <a href="https://livekit.io/cloud?ref=meet" rel="noopener">*/}
          {/*    LiveKit&nbsp;Cloud*/}
          {/*  </a>{' '}*/}
          {/*  and Next.js.*/}
          {/*</h2>*/}
        </div>
        {/*<DemoMeetingTab label="Demo" />*/}
        <Tabs selectedIndex={tabIndex} onTabSelected={onTabSelected}>
          <DemoMeetingTab label="New" />
          <CustomConnectionTab label="Join" />
        </Tabs>
      </main>
      {/*<footer data-lk-theme="default">*/}
      {/*  Hosted on{' '}*/}
      {/*  <a href="https://livekit.io/cloud?ref=meet" rel="noopener">*/}
      {/*    LiveKit Cloud*/}
      {/*  </a>*/}
      {/*  . Source code on{' '}*/}
      {/*  <a href="https://github.com/livekit/meet?ref=meet" rel="noopener">*/}
      {/*    GitHub*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</footer>*/}
    </>
  );
};

export default Home;
